function submitus(elem) {
	$(elem).parents('form').first().submit();
}

var appearingAnim = function (){
	w = $(window);
	wh = w.height();
	wy = w.scrollTop();
	wb = wy+wh;
	b = $('pseudobody');
	bh = b.height();
	bh_isauto = (!b.hasClass('animlimit'));
	$('*[class^="appearing-"]:not(.insight),*[class*=" appearing-"]:not(.insight)').each(function() {
		v = $(this);
		vh = v.height();
		vo = v.offset();
		vey = vo.top + vh;
		if (
			(vo.top >= wy  && vo.top <= wb) || //Se o topo estiver dentro do campo de visão
			(vey >= wy  && vey <= wb) || //Se o final estiver dentro do campo de visão
			(wh < vh && wy >= vo.top && wy <= vey) || //Se a tela for menor que objeto e estiver dentro do campo de visão
			(vo.top < 0 && wy === 0) || // Se o objeto estiver acima do documento e a tela no inicio
			(bh_isauto && vo.top > bh && wb >= bh) // Se o objeto estiver abaixo do documento e a tela no fim
		) { 
			v.addClass('insight');
		}
	});
};

var loadingTransition = function () {
	$('loader').addClass('finished');
	appearingAnim();
};

$(window).load(function(){
	loadingTransition();
	
	if(jQuery().carousel) 
		$('.carousel').carousel();
});

$(document).ready(function() {
	if(!Modernizr.csstransitions || !Modernizr.cssanimations || !Modernizr.csstransforms3d) {
		$('html').removeClass('anim').addClass('no-anim');
	}
	if(jQuery().fancybox) {
    	$('.fancybox').fancybox({
			openMethod : 'signIn',
			closeMethod : 'signOut'
		});
		$('.form2fancybox').submit(function() {
			//Sadly GET only
			var url = $(this).attr("action") + "?" + $(this).serialize();
			$.fancybox({
				openMethod : 'signIn',
				closeMethod : 'signOut',
				href : url,
				'type' : 'iframe'
			});
			return false;
		});
	}
	$('a[href*=#][target="scroll"]:not([href=#])').click(function() {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			if (target.length) {
				$('html,body').animate({
					scrollTop : target.offset().top
				}, 1000);
				return false;
			}
		}
	});
	$('a.tab-switcher[href*=#]:not([href=#])').click(function() {
		target = $($(this).attr('href'));
		wrapper = target.parents('.tabs-wrap').first();
		ex_actives = wrapper.find('.tabs-active');
		ex_actives.find('.insight').removeClass('insight');
		ex_actives.removeClass('tabs-active');
		target.addClass('tabs-active');
		appearingAnim();
		return false;
	});
	if(jQuery().datepicker) 
		$('.input-group.date').datepicker({
			format : "dd/mm/yyyy",
			todayBtn : false,
			language : "pt-BR",
			todayHighlight : true
		});
	$(window).on('scroll', appearingAnim);
	$(window).on('resize', appearingAnim);
}); 